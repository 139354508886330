.cardWrapper {
    width: 15rem;
    height: 7rem;
    border: 1px solid #e3e3e3dd;
    text-align: center;
    border-radius: 1rem;
    position: relative;
    cursor: pointer;
    transition: 0.2s;
    background-color: #fff;
}

.cardWrapper:hover {
    background-color: #f0f0f0;
}

.cardContent {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
}

.img {
    width: 40px;
    margin: 0px auto;
}

.cardTitle {
    font-size: 20px !important;
    font-weight: 300 !important;
}

.count {
    font-size: 13px;
    font-weight: 200;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(255, 255, 255);
    z-index: 1500;
    opacity: 0.8;
    border-radius: 1rem;
}

.overlay > span {
    top: 40%;
    position: relative;
}