/* @import url('assets/styles/inter.css'); */
/* @import url('assets/styles/styles.css'); */

/* body {
    padding: 0 4rem;
}

html {
    line-height: 1.5;
    -webkit-text-size-adjust: 100%;
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-feature-settings: normal;
} */

.mh-10 {
    margin: 0 10px;
}

.mv-10 {
    margin: 10px 0;
}

::-webkit-scrollbar {
    width: 5px;
  }
  
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  ::-webkit-scrollbar-thumb {
    background: rgb(134, 134, 134);
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }