@tailwind base;
@tailwind components;
@tailwind utilities;

button {
    outline: none !important;
}

.m-v-16 {
    margin-top: 2rem;
    margin-bottom: 2rem;
}