.contentWrapper {
    padding-top: 4rem;
    min-height: 100vh;
}
.contentOuter {
    display: flex;
}
.sidePanel {
    width: 15rem;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    height: fit-content;
}
.sItem {
    font-size: 13px;
    padding: 10px;
    border-bottom: 1px solid #dddd;
    cursor: pointer;
    transition: 0.3s;
    font-weight: 400;
}
.sItem:hover {
    background-color: rgb(236, 236, 236);
}
.title  {
    margin: 0 0 16px;
    text-align: center;
    font-weight: 500;
    font-size: 30px;
}
.content {
    font-size: 14px;
    font-weight: 300;
    padding: 1rem 0;
}
.itemIcon {
    width: 25px;
    height: 25px;
    margin-right: 1rem;
}

.wrapperContent {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
    position: relative;
    padding: 16px 0;
}
.wrapperContent::before {
    content: ' ';
    background-image: url('/public/bg.jpg');
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.05
}

.titltSection {
    text-align: center;
    border-bottom: 1px solid #dedede;
    font-size: 18px;
    padding: 16px 0;
    font-weight: 400;
}

.tutorialViewWrapper {
    /* font-size: 14px; */
    /* font-weight: 300; */
    padding: 15px;
}

.t-title {
    font-size: 25px;
    margin-bottom: 15px;
    font-weight: 600;
}

.f-w-300 {
    font-weight: 300;
}

.f-p {
    font-weight: 300;
}

.f-p::first-letter {
    initial-letter: 2;
}

.un-ordered-li {
    list-style: decimal;
    padding-left: 36px;
    margin-top: 16px;
    font-weight: 400;
    font-size: 14px;
}

.main-view {
    padding: 0px 16px;
    width: calc(100% - 12rem);
    position: relative;
    max-height: calc(100vh - 150px);
    overflow: hidden;
    overflow-y: auto;
}