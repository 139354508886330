.navWrapper {
    display: flex;
    width: 100%;
    gap: 3rem;
    background-color: #fff;
}

.nav {
    border: 1px solid #cecece;
    border-radius: 5px;
    padding: 15px;
    cursor: pointer;
    transition: 0.2s;
    display: flex;
}
.nav:hover {
    background-color: #eeeeee;
}

.summary {
    width: 100%;
    font-size: 12px;
    font-weight: 300;
    width: "100%";
    color: #666666;
}

.v-mid {
    color: rgb(174, 174, 174);
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}