footer {
    text-align: center;
    margin: auto;
    font-size: 12px;
    font-weight: 300;
    left: 0;
    right: 0;
    padding: 1.2rem;
    position: relative;
    bottom: 0;
}