.intro-img {
    width: 150px;
    margin: 3rem auto;
}

.inst-steps {
    list-style: auto;
    padding-left: 16px;
}

.inst-steps > li {
    margin: 5px 0;
}

.codeBg {
    padding: 15px;
    background: #444;
    color: #fff;
    border-radius: 5px;
}

.copy {
    margin: 4px 0 0 30px;
    cursor: pointer;
    transition: 0.2s;
    opacity: 0;
    pointer-events: none;
}

.copy-able-txt {
    display: flex;
}

.copy-able-txt:hover > .copy {
    opacity: 1;
    pointer-events: all;
}